import React from "react";
import instaIcon from '../../assets/instaIcon.svg';
import "./footer.styles.scss";

const Footer = () => (
  <div className="main-footer sticky-bottom" id="footer">
    <div className="contaainer">
      <div className="sobrecartaviva">
        <h3>Nosotros, el equipo de Carta Viva</h3>
        <p>
        Entendemos lo que significa ser trabajadores como tú. Nos esforzamos para ayudarte a llevar tu negocio al siguiente nivel. Brinda a tus clientes la mejor experiencia de usuario en el entorno digital. ¡Contáctanos hoy mismo y comienza a recibir pedidos sin pagar comisiones!
        </p>
      </div>
      <div className="contacto">
        <div className="contactanos">
          <h3>CONTACTO</h3>
        </div>

        <div className="detalles">
          <a href={`https://wa.me/51952345486?text=Hola,%0A%0AMe%20interesa%20una%20demo.`}>t: +51 952 345 486</a >
          <div><a href="mailto:hola@cartaviva.pe">hola@cartaviva.pe</a></div>
          <div>Siguenos en instagram, <div><a href="https://www.instagram.com/cartaviva.pe/"><img className="instaIcon" src={instaIcon}/> haz click aqui</a> </div></div>
        </div>
      </div>
    </div>
    <div className="disc">2023 Carta Viva ©</div>
  </div>
);

export default Footer;
