
import styled,{css} from 'styled-components';


export const ShowcaseContainer =styled.div`
display:flex;
flex-wrap:wrap;





`;

export const Trigger = styled.div`

`


export const ButtonTop=styled.div`
width:180px;
border-radius:8px;
border:2px solid teal;
font-size:1.2rem;
font-weight:bold;
margin:0 auto;
margin-top:40px;
margin-bottom:40px;
text-align:center
`;

export const CatalogContainer=styled.div`
width:80%;
@media only screen and (max-width: 1408px) {
		width:80%;

	}
@media only screen and (max-width: 738px) {
		width:100%;

	}
`;

export const ControlContainer = styled.div`
width:20%;

height:100px;
background-color:#509090;
border-top-right-radius:16px;
border-bottom-right-radius:16px;
filter:drop-shadow(0px 5px 2px #00000050);

@media screen and (max-width:768px){
width:100%;
margin:0 auto;


}

`;

export const FiltroTit=styled.h3`
padding-left:80px;
@media screen and (max-width:768px){
display:none;

}

`;
export const Control1 = styled.div`

margin:0 auto;
font-size:1.5rem;
font-weight:bold;

width:100%;
text-align:center;
margin-bottom:10px;
margin-top:10px;
border-radius:8px;

`;
export const Control2 = styled.div`

margin:0 auto;
font-size:1.5rem;
font-weight:bold;

width:100%;
text-align:center;
margin-bottom:10px;
margin-top:10px;
border-radius:8px;

`;
export const Control3 = styled.div`

margin:0 auto;
font-size:1.5rem;
font-weight:bold;
border:1px solid black;
width:350px;
text-align:center;
margin-bottom:10px;
margin-top:10px;
background-color:gray;

`;

export const ContentContainerShow=styled.div`
display:flex;
flex-wrap:wrap;
justify-content: space-between;
min-height:100vh;
margin-top:-100px;
position:relative;
.anchorCard{
		position:absolute;
		left:0;
		top:-310px;
		background-color:blue;
	}
@media screen and (max-width:768px){
width:100%;
margin-top:100;
}
`;



const subColor = 'grey';
const mainColor = 'black';

const shrinkLabelStyles = css`
  top: -14px;
  font-size: 12px;
  color: ${mainColor};
`;

export const CategoryTitle=styled.div`
font-size:1.8rem;
margin-left:18px;
@media only screen and (max-width: 738px) {
		margin:0 auto;
        text-align:center;
        background-color:#509090;
        color:#f5f5f5;
	
	}
`;

export const SelectFormContainer=styled.select`
width:90%;
border:none;
text-align:center;
color:grey;
background-color:white;
margin-top:10px;
`;

export const LabelContainer=styled.div`
${({labelType})=>labelType==="radio"?`border-bottom:1px solid #838592`:""};
text-align:left;
color:#6a6c77;
padding-left:15px;
font-size:1.2rem;
font-weight:bold;
`;

export const LabelContainerTime=styled.div`
  color: ${subColor};
  font-size: 0.62rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 100px;
  top: 10px;
  @media screen and (max-height:668px){
    left: 85px;
}
`;


export const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`;
export const FormInputContainer = styled.input`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;

  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 8px;

  margin: 10px 0;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

export const TimeInputContainer=styled.input`
width:30%;
float:left;
background: none;
font-size:0.8rem;
  background-color: white;
  border: none;
  border-radius: 8px;
  margin-bottom:10px;
  margin-top:10px;
`;

export const InputContainer=styled.div`
position: relative;
margin: 0 auto;
width:95%;
border-radius:80px;
`;
export const CartDropdownContainer = styled.div`
transition:all 0.3s;
width: 100vw;
display: flex;
flex-direction: column;
padding: 20px;
border-radius:16px;
background-color:${({posicion})=>posicion==="bar"?"#BED1D3":"#C4C0FF"};
@media screen and (min-width:768px){

min-width: 400px;
max-width: 400px;
}

`;

export const ProblemaContainer=styled.div`
font-size:0.7em;
width:100%;
margin-left: auto;
margin-right: auto;
text-align: center;
transition:0.3s;
opacity:0;
font-weight:bold;
color:#ad0202;
pointer-events: none;

margin-bottom:10px;

&.visibil{
    opacity:1;
    transform:scaleY(-2px)
    
}
`;
export const DeliveryChoiceContainer=styled.div`
width:100%;
display:flex;

flex-wrap:wrap;
justify-content:space-evenly;

font-size:2rem;
margin-bottom:10px;
`;


export const DeliveryChoiceTitle=styled.div`
font-size:0.8rem;
`;

export const DeliveryChoiceIcon=styled.img`
&.selected{
  filter: invert(93%) sepia(18%) saturate(347%) hue-rotate(316deg) brightness(108%) contrast(90%);
}
`;



export const CartItemsContainer = styled.div`
display: flex;
flex-direction: column;

overflow: hidden;

`;


export const EmptyMessage = styled.span`
font-size: 18px;
margin: 50px auto;
text-align:center;
color:gray;
`;

export const CarritoTristeCont=styled.img`
display:block;
padding:10px;
margin:0 auto;
`
export const CheckoutTitle=styled.div`
font-size:1.4rem;
text-align:center;
font-weight:bold;
border-bottom:2px solid #51585c;
border-radius:16px;
border-bottom-left-radius:0px;
border-bottom-right-radius:0px; 
width:100%;
text-align:left;
padding-left:10px;
position:relative;

`;

export const TitleIcon=styled.img`
position:absolute;
right:10px;
bottom:0px;
filter: invert(33%) sepia(8%) saturate(472%) hue-rotate(157deg) brightness(92%) contrast(86%);
`;

export const CartTotalContainer = styled.div`
width:60%;
margin:0 auto;
display:flex;
flex-wrap:wrap;
justify-content:space-between;
position:relative;
margin-bottom:20px;
`;



export const CartDeleteContainer=styled.div`
position:absolute;
font-size:0.7rem;
right:-50px;
top:-8px;
font-size:0.8em;
`;