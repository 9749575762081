import React,{useState,useEffect} from 'react';
import Card from '../Card/card.component';
import {ShowcaseContainer,CategoryTitle,FiltroTit,Trigger,ContentContainerShow,ControlContainer,Control1,Control2,Control3, LabelContainer,ButtonTop,CatalogContainer,InputContainer,FormInputContainer, FormInputLabel} from './showcase.styles';
import FormInput from "../form-input/form-input.component"
import Collapsible from 'react-collapsible';
import flechaDerecha from '../../assets/arrowRight.png'
import flechaIzquierda from '../../assets/arrowLeft.png'
import ReactGA from 'react-ga4';


const Showcase =({loc,customers,mobile})=>

{
   
    const [filtros,setFiltros]=useState({
        tipoComida:"",
        distrito:"",
        nombre:""
    })
          
    useEffect(() => {
    document.getElementById('anchorCard').scrollIntoView(  {behavior: "smooth"});
    
    ReactGA.event({action:'pageview',category:"HomePage",label:`${loc==="rest"?"Restaurantes":"Markets"}`})  
    
    }, []);

 

    const handleChange= event=>{
        const{value,name}=event.target;
        setFiltros({...filtros,[name]:value});
    };
    return(
<ContentContainerShow>
<div id="anchorCard" className="anchorCard"></div>
   <ControlContainer>

        <Control1>
  

        <FormInput label="Tipo de Comida" onChange={handleChange}tag="select" name="tipoComida"  default="Nada"choices={[{Es:"Todo",En:"Todo",default:true},"Burgers","Pizza","Bowls","Todos"]}/>

        </Control1>
        {/* <Control2>
        <FormInput label="Distrito" onChange={handleChange}tag="select" name="distrito"  choices={[{Es:"Todo",En:"Todo",default:true},"La Molina","Surco","Miraflores","Todos"]}/>
        </Control2> */}




 
    </ControlContainer>
    <CatalogContainer>
 
                    <InputContainer>
                    <FormInputContainer name="nombre" value={filtros.nombre } onChange={handleChange}/>
    
                    <FormInputLabel className={`${filtros.nombre ? 'shrink' : ''} form-input-label`}>
                     Buscar por nombre...
                    </FormInputLabel>
    
                </InputContainer>
    <CategoryTitle>{loc==="rest"?"Restaurantes":"Markets"}</CategoryTitle>
        <ShowcaseContainer>
            {Object.keys(customers).map(customer=>filtros.tipoComida.replace("Todos","")?customers[customer].restFoodTypes.includes(filtros.tipoComida)?customers[customer].name.toLocaleLowerCase().includes(filtros.nombre.toLocaleLowerCase())?<Card linkUrl={customers[customer].linkUrl} restTitle={customers[customer].name} restImg={customers[customer].restImg} restImg4={customers[customer].restImg4} restImg5={customers[customer].restImg5} restImg6={customers[customer].restImg6} restImg2={customers[customer].restImg2} restImg3={customers[customer].restImg3} restLoc={customers[customer].restLoc} restFoodTypes={customers[customer].restFoodTypes}/>:"":"":customers[customer].name.toLocaleLowerCase().includes(filtros.nombre.toLocaleLowerCase())?<Card largo={customers[customer].largo}  linkUrl={customers[customer].linkUrl} restTitle={customers[customer].name} restImg={customers[customer].restImg} restImg4={customers[customer].restImg4} restImg5={customers[customer].restImg5} restImg6={customers[customer].restImg6} restImg2={customers[customer].restImg2} restImg3={customers[customer].restImg3} restLoc={customers[customer].restLoc} restFoodTypes={customers[customer].restFoodTypes}/>:"" )}
        
        </ShowcaseContainer>
       {filtros.nombre||filtros.tipoComida.replace("Todos","")||filtros.distrito.replace("Todos","")?"":<div>
       {loc==="rest"?<div>
                <CategoryTitle>{loc==="rest"?"Burgers":"Para cocinar en casa"}</CategoryTitle>
                <ShowcaseContainer>
                    {Object.keys(customers).map(customer=>customers[customer].restFoodTypes.includes(`${loc==="rest"?"Burgers":"Carne"}`)?<Card largo={customers[customer].largo}  linkUrl={customers[customer].linkUrl} restTitle={customers[customer].name} restImg={customers[customer].restImg} restImg4={customers[customer].restImg4} restImg5={customers[customer].restImg5} restImg6={customers[customer].restImg6} restImg2={customers[customer].restImg2} restImg3={customers[customer].restImg3} restLoc={customers[customer].restLoc} restFoodTypes={customers[customer].restFoodTypes}/>:"" )}
                </ShowcaseContainer>
            </div>:""}

            {loc==="rest"?<div>
                <CategoryTitle>{loc==="rest"?"Pizza":"Para cocinar en casa"}</CategoryTitle>
                <ShowcaseContainer>
                    {Object.keys(customers).map(customer=>customers[customer].restFoodTypes.includes(`${loc==="rest"?"Pizza":"Carne"}`)?<Card largo={customers[customer].largo}  linkUrl={customers[customer].linkUrl} restTitle={customers[customer].name} restImg={customers[customer].restImg} restImg4={customers[customer].restImg4} restImg5={customers[customer].restImg5} restImg6={customers[customer].restImg6} restImg2={customers[customer].restImg2} restImg3={customers[customer].restImg3} restLoc={customers[customer].restLoc} restFoodTypes={customers[customer].restFoodTypes}/>:"" )}
                </ShowcaseContainer>
            </div>:""}
            
       </div>}
       {mobile?<ButtonTop onClick={()=>document.getElementById('Cvanchor').scrollIntoView(  {behavior: "smooth"})}>Subir</ButtonTop>:""}
    </CatalogContainer>
</ContentContainerShow>)};

export default Showcase



//{loc==="rest"?<div>
//                <CategoryTitle>{loc==="rest"?"Bowls":"Para cocinar en casa"}</CategoryTitle>
//                <ShowcaseContainer>
//                    {Object.keys(customers).map(customer=>customers[customer].restFoodTypes.includes(`${loc==="rest"?"Bowls":"Dulce"}`)?<Card largo={customers[customer].largo}  linkUrl={customers[customer].linkUrl} restTitle={customers[customer].name} restImg={customers[customer].restImg} restImg4={customers[customer].restImg4} restImg5={customers[customer].restImg5} restImg6={customers[customer].restImg6} restImg2={customers[customer].restImg2} restImg3={customers[customer].restImg3} restLoc={customers[customer].restLoc} restFoodTypes={customers[customer].restFoodTypes}/>:"" )}
//                </ShowcaseContainer>
//            </div>:""}