import React from 'react';
import './App.scss';
import Cards from './components/Cards/cards.component'
import Header from './components/Header/header.component'
import Body from './components/Body/Body.component'
import Footer from './components/Footer/footer.component'
import ReactGA from 'react-ga4';

import {Route, Switch} from 'react-router';

function App() {
React.useEffect(() => {
    ReactGA.initialize('G-MDZ6431EPF')
  }, [])
  return (
    <div className="App">
      
         <div className="conteiner">
           <Header id={''}/>
         
          <Switch>
            <Route exact path='/informacion' component={Body}/>


            <Route  path='/' component={Cards}/>
              

          </Switch>
         </div>

         <Footer style={{position:"sticky-bottom"}}/>
        
    </div>
  );
}

export default App;
