import styled, { css } from 'styled-components';
import {motion} from 'framer-motion'
const subColor = 'white';
const mainColor = 'light gray';

const shrinkLabelStyles = css`
  top: -14px;
  font-size: 12px;
  color: ${mainColor};
`;

export const Logo=styled.img`
position:absolute;

`;

export const FormTainer=styled.div`

position:relative;
background-color:#509090;
width:50%;
margin:0 auto;
border-radius:27% 100% 63% 0% / 26% 0% 21% 100%;
padding:30px;
@media only screen and (max-width: 738px) {
  width:100%;

}
@media only screen and (max-height: 800px) {
  margin-top:10%;
 margin-bottom:-150px;
}
`;

export const TituloForm=styled.div`
font-size:1.7rem;
color:#f5f5f5;
position:relative;
font-weight:bold;
padding-left:5%;
@media (max-width: 738px) {
  padding:10px;}
`;

export const Anchorin=styled.div`
position:absolute;
top:-105px;
`;


export const ButtonRegister=styled(motion.div)`
width:180px;
border:1px solid #f5f5f5;
border-radius:8px;
margin: 0 auto;
margin-top:5px;
transition:0.3s;
background-color:#509090;
    color:#f5f5f5;
  text-align:center;

a,:active,:hover,:visited,:link{
    color:#f5f5f5;
    text-decoration: none;
    font-weight:bold;
    &:hover{
        color:#f5f5f5;
    }
}
a:hover{
    color:#f5f5f5;
}
&:hover{
    background-color:#509090;
    color:#f5f5f5;

}

`;

export const ChoiceContainer=styled.span`
position:absolute;
top:9px;
left:34px;
color:#6a6c77;

`;

export const LabelContainer=styled.div`

text-align:left;
color:#6a6c77;
padding-left:15px;
font-size:1.2rem;
font-weight:bold;

`;

export const InputContainer=styled.div`
position: relative;
margin: 0 auto;
width:90%;
border-right: 0px
`;
export const GroupContainer = styled.div`
  position: relative;
  margin-bottom:10px;
  input[type='password'] {
    letter-spacing: 0.3em;
  }
  input[type='checkbox'],
  input[type='radio'] {
    
    --active: #d41f71;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);

    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 auto;
   
    cursor: pointer;

    background: var(--b, var(--background));

    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);

      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
 
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {

      &:after {
        width: 5px;
        height: 9px;
  
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;

      &:after {
        left: 2px;
        top: 2px;

        width: 15px;
        height: 15px;

        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
 margin-bottom:10px;
    margin-left:70%;

    
    &:after {
      width: 19px;
      height: 19px;
    
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }

`;

export const SelectFormContainer=styled.select`
width:90%;

text-align:center;
color:grey;
background-color:white;
`;
export const FormRadioContainer=styled.div`
position:relative;

align-items:left;

padding-top:10px;
`;
export const OptionStacker=styled.div`

`;
export const FormInputContainer = styled.input`
  background: none;

border:none;
border-bottom:2px solid #f5f5f5;
  color: ${subColor};
  font-size: 18px;

  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;


  margin: 25px 0;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

export const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`;