import styled from 'styled-components'

export const CardContainer =styled.div`

margin:0 auto;
width:240px;
position:relative;
margin:20px;
background-color:#1F1F1F;
border-radius: 8px;
background: #1F1F1F;
box-shadow:  7px 7px 6px #0a0a0a,
             -7px -7px 6px #262626;
@media screen and (max-width:768px){
width:100%;
margin:0 auto;
box-shadow:none;

}
`;

export const AcceptButton=styled.div`
width:180px;
border:1px solid #509090;
border-radius:8px;
margin: 0 auto;
margin-top:5px;
transition:0.3s;
background-color:#509090;
    color:#f5f5f5;

   

a,:active,:hover,:visited,:link{
    color:#f5f5f5;
    text-decoration: none;
    font-weight:bold;
    &:hover{
        color:#f5f5f5;
    }
}
a:hover{
    color:#f5f5f5;
}
&:hover{
    background-color:#509090;
    color:#f5f5f5;

}

`;

export const Title =styled.div`
font-size:1.2rem;
line-height:1.2rem;
font-weight:bold;
height:2.4rem;
padding:5px;
margin:5px;
color:white;
${({largo})=>largo?"font-size:0.9rem;":""}
`;
export const ContentTainer =styled.div`
text-align:center;
margin:0 auto;
color:white;
padding:20px;
padding-top:5px;
background-color:#1F1F1F;
border-radius:8px;
height:fit-content;
@media only screen and (max-width: 738px) {
		border-radius:0;
	}

`;

export const Image =styled.img`
min-width:240px;
border-radius:8px;
border-bottom-left-radius:0px;
border-bottom-right-radius:0px;
@media only screen and (max-width: 738px) {
		width:100%;
    border-radius:0px;
	}
`;

export const Location =styled.div`
font-weight:bold;
color:#509090
`;

export const FoodType =styled.div`

`;

export const InfoContainer =styled.div`

`;

export const SwiperContainer =styled.div`
width:100%;
`;

export const b =styled.div``;
