


import React from 'react';

import {FormInputLabel,FormInputContainer,GroupContainer,SelectFormContainer,FormRadioContainer,ChoiceContainer,LabelContainer,InputContainer} from './form-input.styles'


const FormInput=({onChange, lenguaje,onChangeText,label,tag, title,name,choices,...otherProps})=>{

    console.log(otherProps.value)
    return (
        tag === "input" ?
            <InputContainer>
                <FormInputContainer name={name} onChange={onChangeText} {...otherProps} />

                <FormInputLabel className={`${otherProps.value.length ? 'shrink' : ''} form-input-label`}>
                    {label}
                </FormInputLabel>

            </InputContainer>

            :
            tag === "select" ?
            <GroupContainer >
            <LabelContainer labelType={"select"}>{label}</LabelContainer>
            <SelectFormContainer name={name} onChange={onChange} >
    {choices.map((choice,index)=>choice.default? <option key={index} style={{color:"#cf1e1e"}} selected hidden  value={choice.Es}>{lenguaje==="es"?choice.Es:choice.En}</option>:<option key={index} value={choice}>{choice}</option>)}
            </SelectFormContainer>
            </GroupContainer> :

              tag==="check"?
                  <GroupContainer name={name} {...otherProps}>
            <LabelContainer labelType={"checkbox"}>{label}</LabelContainer>
            {choices.map((choice,index) =>
            <FormRadioContainer key={index}>
            <input type="checkbox" value={choice}  onChange={onChange}  name={name} defaultChecked /><ChoiceContainer>{choice}</ChoiceContainer>
            </FormRadioContainer>
            )}
            </GroupContainer>
              :
            
            <GroupContainer name={name} {...otherProps}>
            <LabelContainer labelType={"radio"}>{label}</LabelContainer>
            {choices.map((choice,index) => choice.default?
            <FormRadioContainer key={index}>
            <input type="radio" value={choice}  onChange={onChange}  name={name} defaultChecked /><ChoiceContainer>{choice}</ChoiceContainer>
            </FormRadioContainer>
            :
          
            <FormRadioContainer key={index}>
            <input type="radio" value={choice} onChange={onChange}  name={name} /><ChoiceContainer>{choice}</ChoiceContainer>
            </FormRadioContainer>
            )}
            </GroupContainer>


    
    )
}




//            <SelectFormContainer name={name} onChange={handleChange} value={otherProps.value} {...otherProps} >
//{choices.map((choice,index) =>choice.default? <option key={index} style={{color:"#cf1e1e"}} hidden disabled value={JSON.stringify(choice)}>{choice.En}</option>:<option  key={index} value={JSON.stringify(choice)} >{choice.En}</option>)}
//</SelectFormContainer>

export default FormInput