import React, {useEffect} from 'react';
import {useAnimation, motion} from 'framer-motion';
import Screen1 from '../../assets/screen1.png';
import { useInView,InView } from 'react-intersection-observer';

const Box =({children,invertido,screen,title,icon})=>{
    const controls = useAnimation();
    const [ref, inView] = useInView({
      threshold:0.55
    });
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

const boxVariants={
       hidden:{opacity:0,y:60},
       visible:{
               opacity:1,y:0,
               
               transition:{
                       duration:0.6
               }
       }
}


const boxImageVariants1={
  hidden:{scale:0},
  visible:{
    scale:1,
    transition:{
      duration:0.5
    }
  }
}

return(
    <motion.div className="benCont" ref={ref} initial="hidden" animate={controls} variants={boxVariants}>
    {invertido?<motion.img src={screen}className="benContImg"></motion.img>:""}

    <motion.div className="benContDesc"> <div className="benContTit">{title}</div>{children} <div className="benContIcon"  onClick={()=>document.getElementById('Anchorin').scrollIntoView(  {behavior: "smooth"})}>Quiero Comenzar!</div></motion.div>
    {invertido?"":<motion.img src={screen}className="benContImg"></motion.img>}
   
</motion.div>
)
}

export default Box;