import React,{useEffect,useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';

import ReactGA from 'react-ga4';
// Import Swiper styles
import 'swiper/swiper.scss';

import {Title,AcceptButton, ContentTainer,CardContainer, Image,Location,FoodType,InfoContainer,SwiperContainer} from './card.style'





const Card =({restImg, restImg2, restImg3,restImg4,restImg5,restImg6,restTitle,restLoc,restFoodTypes,largo,linkUrl})=>{
    SwiperCore.use([EffectFade]);
    const [windowSize, setWindowWidth] = useState(window.innerWidth);
    const [search,setSearch]=useState("");
   
    const handleOnClick = () => {
      // Track click on button
  
      ReactGA.event({action:'pageview',category:restTitle,label:"Click Carta"});
      
      }

      const mobile = windowSize >= 650 ? false : true;

      useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
       
        window.addEventListener('resize', handleWindowResize);
        setSearch("")
    
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [])

    
return(
<CardContainer>
<SwiperContainer >
      <Swiper
  
            spaceBetween={0}
            slidesPerView={1}
            autoplay
           
            fadeEffect
            effect="fade"
          >
            <SwiperSlide style={{padding:0}}><Image src={mobile?restImg4:restImg}/></SwiperSlide>
            <SwiperSlide style={{padding:0}}><Image src={mobile?restImg5:restImg2}/></SwiperSlide>
            <SwiperSlide style={{padding:0}}><Image src={mobile?restImg6:restImg3}/></SwiperSlide>
          </Swiper>
  </SwiperContainer>
<ContentTainer>

      <Title largo={largo}>{restTitle}</Title>
      <Location>{restLoc}</Location>
      <FoodType>{restFoodTypes.map(restFoodType=>` ${restFoodType}`).toString()}</FoodType>
      <AcceptButton><a onClick={()=>handleOnClick()}href={`${linkUrl}`} target="_blank" rel="noopener noreferrer">Ver Carta</a></AcceptButton>
</ContentTainer>

</CardContainer>)}

export default Card