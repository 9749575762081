import React,{useState,useEffect} from 'react';
import './cards.styles.scss';
import {Link,Route} from 'react-router-dom';
import Showcase from '../showcase-component/showcase.component'
import Suvid from '../../assets/Suvid.jpg'
import tv from '../../assets/tv.png'
import nushito from '../../assets/ishito.png'
import Card from '../Card/card.component'
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper';
import "../../../node_modules/swiper/swiper-bundle.css" 
import { Swiper, SwiperSlide } from 'swiper/react';
import logocaleta from '../../assets/logocaleta.png'


import logoraga from '../../assets/logoRaga.png'
import logosuvid from '../../assets/logosuvid.png'
import logoestate from '../../assets/logoestate.png'
import logoginza from '../../assets/logopuraterra.png'

import logoApenny from '../../assets/logoApenny.png'
import logoFundo from '../../assets/logoFundo.png'
import logoDjango from '../../assets/logoDjango.png'

import rayo from '../../assets/rayo.svg'
import filtros from '../../assets/filtros.svg'
import descuento from '../../assets/descuentos.svg'
import whatsapp from '../../assets/whatsapp.svg'
import ReactGA from 'react-ga4';



// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


SwiperCore.use([Navigation, Pagination, Scrollbar,Autoplay, A11y]);





const Cards =({id,history})=>{
  const [windowSize, setWindowWidth] = useState(window.innerWidth);
  const [search,setSearch]=useState("");
const mobile = windowSize >= 650 ? false : true;

const handleOnClick = (title) => {
    // Track click on button
    ReactGA.event({action:'pageview',category:title,label:"Click Banner"})
      }
useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
   
    window.addEventListener('resize', handleWindowResize);
    setSearch("")

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
}, []);
const Restaurantes={
  
  Puraterra:{
    name:"Pura Terra",
    restLoc:"Punta Hermosa, Miraflores",
    restImg:"https://www.cartaviva.pe/images/landing/thumbs/puraterra1200.jpg",
    restImg2:"https://www.cartaviva.pe/images/landing/thumbs/puraterra2200.jpg",
    restImg3:"https://www.cartaviva.pe/images/landing/thumbs/puraterra3200.jpg",
    restImg4:"https://www.cartaviva.pe/images/landing/thumbs/puraterra1500.jpg",
    restImg5:"https://www.cartaviva.pe/images/landing/thumbs/puraterra2500.jpg",
    restImg6:"https://www.cartaviva.pe/images/landing/thumbs/puraterra3500.jpg",
    restFoodTypes:["Power Bowls","Açai Bowls"],
    linkUrl:"https://www.cartaviva.pe/puraterra/salon"
  },
  Ragazzi:{
    name:"Ragazzi Pizza Artesanal",
    restLoc:"Barranco, Surquillo",
   
    restImg:'https://www.cartaviva.pe/images/ragazzi/thumbs/americana200.jpg',
    restImg2:'https://www.cartaviva.pe/images/ragazzi/thumbs/hawaiana200.jpg',
    restImg3:'https://www.cartaviva.pe/images/ragazzi/thumbs/ragazzi200.jpg',
    restImg4:'https://www.cartaviva.pe/images/ragazzi/thumbs/americana200.jpg',
    restImg5:'https://www.cartaviva.pe/images/ragazzi/thumbs/hawaiana200.jpg',
    restImg6:'https://www.cartaviva.pe/images/ragazzi/thumbs/ragazzi200.jpg',
    restFoodTypes:["Pizza"],
    linkUrl:"https://www.cartaviva.pe/ragazzi/salon"
  },
  DjangoBurgers:{
    name:"Django Burgers",
    restLoc:"Miraflores",

    restImg:'https://www.cartaviva.pe/images/djangoburgers/thumbs/animalconpapas.jpg',
    restImg2:'https://www.cartaviva.pe/images/djangoburgers/thumbs/cheeseburgerdoble.jpg',
    restImg3:'https://www.cartaviva.pe/images/djangoburgers/thumbs/clasicadobleconpapas.jpg',
    restImg4:'https://www.cartaviva.pe/images/djangoburgers/thumbs/animalconpapas.jpg',
    restImg5:'https://www.cartaviva.pe/images/djangoburgers/thumbs/cheeseburgerdoble.jpg',
    restImg6:'https://www.cartaviva.pe/images/djangoburgers/thumbs/clasicadobleconpapas.jpg',
    restFoodTypes:["Burgers"],
    linkUrl:"https://www.cartaviva.pe/djangoburgers/salon"
  }
}



const Markets={
  ElVecino:{
    name:"El Vecino",
    restLoc:"Todo Lima",
    restImg:"https://www.cartaviva.pe/images/elvecino/thumbs/pulledporkpack.jpg",
    restImg2:"https://www.cartaviva.pe/images/elvecino/thumbs/pulledpork.jpg",
    restImg3:"https://www.cartaviva.pe/images/elvecino/thumbs/costillardecerdo.jpg",
    restImg4:"https://www.cartaviva.pe/images/elvecino/thumbs/pulledporkpack.jpg",
    restImg5:"https://www.cartaviva.pe/images/elvecino/thumbs/pulledpork.jpg",
    restImg6:"https://www.cartaviva.pe/images/elvecino/thumbs/costillardecerdo.jpg",
    restFoodTypes:["Carnes","BBQ","Parrilla"],
    linkUrl:"https://www.cartaviva.pe/elvecino/salon"
  },
  Jump:{
    name:"Jump Superfoods",
    restLoc:"Todo Lima",
    restImg:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/mixperuanazo.jpg",
    restImg2:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/macanegra.jpg",
    restImg3:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/matcha.jpg",
    restImg4:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/mixperuanazo.jpg",
    restImg5:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/macanegra.jpg",
    restImg6:"https://www.cartaviva.pe/images/jumpsuperfoods/thumbs/matcha.jpg",
    restFoodTypes:["Super Food","Natural"],
    linkUrl:"https://www.cartaviva.pe/jumpsuperfoods/salon"
  },
  Suvid:{
    name:"Suvid",
    restLoc:"Todo Lima",
    restImg:"https://www.cartaviva.pe/images/landing/thumbs/suvidt1.jpg",
    restImg2:"https://www.cartaviva.pe/images/landing/thumbs/suvdt2.jpg",
    restImg3:"https://www.cartaviva.pe/images/landing/thumbs/suvidt3.jpg",
    restImg4:"https://www.cartaviva.pe/images/suvid/Magret1.jpg",
    restImg5:"https://www.cartaviva.pe/images/suvid/Confit1.jpg",
    restImg6:"https://www.cartaviva.pe/images/suvid/Pulpo4.jpg",
    restFoodTypes:["Carnes","Sou Vide"],
    linkUrl:"https://www.cartaviva.pe/suvid/salon"
  },
  AlePenny:{
    name:"Alessandra Penny Bakery",
    restLoc:"Miraflores",
    restImg:'https://www.cartaviva.pe/images/apbakery/thumbs/tortadealfajor200.jpg',
    restImg2:'https://www.cartaviva.pe/images/apbakery/thumbs/Tortadechocolate2.jpg',
    restImg3:'https://www.cartaviva.pe/images/apbakery/thumbs/Deliciademango2.jpg',
    restImg4:'https://www.cartaviva.pe/images/apbakery/thumbs/tortadealfajor200.jpg',
    restImg5:'https://www.cartaviva.pe/images/apbakery/thumbs/Tortadechocolate2.jpg',
    restImg6:'https://www.cartaviva.pe/images/apbakery/thumbs/Deliciademango2.jpg',
    restFoodTypes:["Postres"],
    linkUrl:"https://www.cartaviva.pe/apennybakery/salon"
  },
  FundoZegarra:{
    name:"Fundo Zegarra",
    restLoc:"Surco",
    restImg:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesoandino.jpg',
    restImg2:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesofinashierbas.jpg',
    restImg3:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesoparia.jpg',
    restImg4:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesoandino.jpg',
    restImg5:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesofinashierbas.jpg',
    restImg6:'https://www.cartaviva.pe/images/fundozegarra/thumbs/quesoparia.jpg',
    restFoodTypes:["Quesos"],
    linkUrl:"https://www.cartaviva.pe/fundozegarra/salon"
  },
  EstateGelatto:{
    name:"Estate Gelato",
    restLoc:"La Molina",
    restImg:"https://www.cartaviva.pe/images/landing/thumbs/EGmaracuya.jpg",
    restImg2:"https://www.cartaviva.pe/images/landing/thumbs/EGlucuma.jpg",
    restImg3:"https://www.cartaviva.pe/images/landing/thumbs/EGchirimoya.jpg",
    restImg4:"https://www.cartaviva.pe/images/estategelato/maracumango.jpg",
    restImg5:"https://www.cartaviva.pe/images/estategelato/lucuma.jpg",
    restImg6:"https://www.cartaviva.pe/images/estategelato/chirimoya.jpg",
    restFoodTypes:["Gelato","Postres"],
    linkUrl:"https://www.cartaviva.pe/estategelato/salon"
  }
}
  
  
const seleccion=history.location.pathname.replace("/","")
  
  console.log(seleccion==="Restaurantes")
  
  return(

<div className="container2" id="Cvanchor">

  <div className="anchor"></div>
  <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
        loop
        autoplay
      pagination={{ clickable: true }}
     
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      style={{minHeight:"80vh",marginTop:"-12vh"}}
    
    >
      <SwiperSlide style={{backgroundImage:`url(${mobile?"https://www.cartaviva.pe/images/landing/banners/bannerpennymobil.jpg":"https://www.cartaviva.pe/images/landing/banners/bannerpennyweb.jpg"})`,backgroundSize:"cover",minHeight:"80vh"}}><div className="slideContent"><p className={mobile?`bannerButtonMob hover`:`bannerButton`}><a onClick={()=>handleOnClick("apennybakery")} href="https://www.cartaviva.pe/apennybakery/salon" target="_blank" rel="noopener noreferrer">Ver Carta</a></p><img  className="logoCont" src={logoApenny}/></div></SwiperSlide>
      <SwiperSlide style={{backgroundImage:`url(${mobile?"https://www.cartaviva.pe/images/landing/banners/bannerdjangomobil.jpg":"https://www.cartaviva.pe/images/landing/banners/bannerdjangoweb.jpg"})`,backgroundSize:"cover",minHeight:"80vh"}}><div className="slideContent"><p className={mobile?`bannerButtonMob hover`:`bannerButton`}><a onClick={()=>handleOnClick("djangoburgers")} href="https://www.cartaviva.pe/djangoburgers/salon" target="_blank" rel="noopener noreferrer">Ver Carta</a></p><img  className="logoCont" src={logoDjango}/></div></SwiperSlide>
      <SwiperSlide  style={{backgroundImage:`url(${mobile?"https://www.cartaviva.pe/images/landing/banners/bannersuvidmobil.jpg":"https://www.cartaviva.pe/images/landing/banners/bannersuvidweb.jpg"})`,backgroundSize:"cover",minHeight:"80vh"}}><div className="slideContent"><p className={mobile?`bannerButtonMob hover`:`bannerButton`}><a onClick={()=>handleOnClick("suvid")} href="https://www.cartaviva.pe/suvid/salon" target="_blank" rel="noopener noreferrer">Ver Carta</a></p><img  className="logoCont" src={logosuvid}/></div></SwiperSlide>
      <SwiperSlide  style={{backgroundImage:`url(${mobile?"https://www.cartaviva.pe/images/landing/banners/bannerragazzimovil.jpg":"https://www.cartaviva.pe/images/landing/banners/bannerragazziweb.jpg"})`,backgroundSize:"cover",minHeight:"80vh"}}><div className="slideContent"><p className={mobile?`bannerButtonMob hover`:`bannerButton`}><a onClick={()=>handleOnClick("ragazzi")} href="https://www.cartaviva.pe/ragazzi/salon" target="_blank" rel="noopener noreferrer">Ver Carta</a></p><img  className="logoCont" src={logoraga}/></div></SwiperSlide>
      <SwiperSlide style={{backgroundImage:`url(${mobile?"https://www.cartaviva.pe/images/landing/banners/bannerzegarramobil.jpg":"https://www.cartaviva.pe/images/landing/banners/bannerfundoweb.jpg"})`,backgroundSize:"cover",minHeight:"80vh"}}><div className="slideContent"><p className={mobile?`bannerButtonMob hover`:`bannerButton`}><a onClick={()=>handleOnClick("fundozegarra")} href="https://www.cartaviva.pe/fundozegarra/salon" target="_blank" rel="noopener noreferrer">Ver Carta</a></p><img className="logoCont"  src={logoFundo}/></div></SwiperSlide>

    </Swiper>
    <div className="beneCont">
      <span><img className="Icon" src={whatsapp}/>Atención Personalizada</span>
 
      <span><img className="Icon" src={filtros}/>Elige tus preferencias</span>

      <span><img className="Icon" src={rayo}/>Fácil y rápido</span>

      <span><img className="Icon" src={descuento}/>Promociones especiales</span>
    </div>
    <div className="buttonHold">

            <Link style={{textDecoration:"none"}} to="/" className={`buttonMarket ${seleccion===""?"selected":"notSelected"}`} >Restaurantes</Link>
            <Link style={{textDecoration:"none"}} to="/markets" className={`buttonMarket ${seleccion==="markets"?"selected":"notSelected"}`} >Markets</Link>
    </div>
    <Route  exact path='/' render={props=><Showcase  mobile={mobile} loc="rest"customers={Restaurantes}/> }/>
    <Route  exact path='/markets' render={props=><Showcase mobile={mobile}  loc="market"customers={Markets}/> }/>
</div>
)}

export default Cards


//  <section id='sec1' className='section1'>
//<div className="anchori" id="Cvanchor"></div>
//<div className="CarouselContainer">
//
//</div>
//        <div id='content'className='contentContainer visible'>
//<div className="herocard2 inverted">
//
//        <div className="cartaviva">Unete a nosotros</div>
//        <div className="slogan" >Eleva tu experiencia</div>
//        <span className='calltoaction'  onClick={()=>document.getElementById('Anchorin').scrollIntoView(  {behavior: "smooth"})}>Me interesaría conocer más</span>
//</div>
//
//<div className="duenorest">
//
//
//
//</div>
//
//</div>
//
//
//</section>