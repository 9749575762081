import React,{useState, useEffect} from 'react';
import {FormInputLabel,FormInputContainer,FormTainer,InputContainer,ButtonRegister,TituloForm,Anchorin} from './form.styles'
import Logo from '../../assets/logo.png'
import ReactGA from 'react-ga4';
const Form =()=>{

const [datos,setDatos]=useState({

    nombre:'',
    nombreNegocio:'',
    correo:''

})
const handleOnClick1 = () => {
    // Track click on button
    ReactGA.send({event:'form_start'})
    }

const handleOnClick = () => {
    // Track click on button
    ReactGA.send({event:'form_submit'})
    ReactGA.event({action:'pageview',category:"Submit Form",label:"Formulario Completo"})
    }

useEffect(() => {
    console.log(datos);
  }, [datos.nombre]);


const handleChange= async event=>{
    const{value,name}=event.target;
    await setDatos({...datos,[name]:value});
   
};
return(
    <FormTainer >
        <Anchorin id="Anchorin"/>
        <TituloForm id="Form">¡Hola, soy Majo, de Carta Viva, déjame tus datos y hablemos! </TituloForm>
        <InputContainer>
        <FormInputContainer name="nombre" onChange={handleChange} onClick={handleOnClick1} />
        <FormInputLabel className={`${datos.nombre.length ? 'shrink' : ''} form-input-label`}>
            Nombre
        </FormInputLabel>
        
        </InputContainer>
        <InputContainer>
        <FormInputContainer name="correo" onChange={handleChange}  />
        <FormInputLabel className={`${datos.correo.length ? 'shrink' : ''} form-input-label`}>
            Dirección de correo
        </FormInputLabel>
        
        </InputContainer>
        <InputContainer>
        <FormInputContainer name="nombreNegocio" onChange={handleChange}  />
        <FormInputLabel className={`${datos.nombreNegocio.length ? 'shrink' : ''} form-input-label`}>
            Nombre de negocio
        </FormInputLabel>
        
        </InputContainer>
        <ButtonRegister whileHover={{scale:1.2}}> <a onClick={()=>handleOnClick}  href={`https://wa.me/51952345486?text=Hola,%20mi%20nombre%20es:%0A*${datos.nombre.replace(/\s/g,"%20")}*%0A%0AMi%20negocio%20se%20llama:%0A*${datos.nombreNegocio.replace(/\s/g,"%20")}*%0Ay%20mi%20correo%20es:%0A*${datos.correo.replace(/\s/g,"%20")}*%0A%0AMe%20interesa%20una%20demo.`} target="_blank" rel="noopener noreferrer">Enviar</a></ButtonRegister>
    </FormTainer>

)};

export default Form