import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo.png'

import './header.styles.scss';

class Header extends Component{
    componentDidMount(){
        window.addEventListener('scroll',()=>{
            const isTop= window.scrollY>100;
            const nav=document.getElementById('nav');
            if (isTop){
                nav.classList.add('scrolled')
            }else{
                nav.classList.remove('scrolled')
            }
        })
    }
    componentWillUnmount(){
        window.removeEventListener('scroll');
    }

render(){

return(<div>
  
    <nav id="nav" className="nav sticky-top">
      <div className="colorBar"></div>
  <div className="headerContent">
  
            <div className="logo" onClick={()=>document.getElementById('Cvanchor').scrollIntoView(  {behavior: "smooth"})}>
              <img src={Logo} alt="" loading="lazy"/>
            </div>
            <ul>
              <li> <Link to="/">Cartas</Link></li>
              <li> <Link to="/informacion">Únete</Link></li>
            </ul>
  </div>
  
    </nav>
</div>

)}
}
export default Header

/*
      <li> <a href="#">Para restaurante</a></li>
      <li> <a href="#">Nosotros</a></li>
*/