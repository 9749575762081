import React, { useEffect, useState } from "react";

import join1 from "../../assets/landing1.png";

import join2 from "../../assets/join2.png";

import join3 from "../../assets/landing3.png";

import join4 from "../../assets/landing2.png";
import join5 from "../../assets/join5.png";
import Screen3 from "../../assets/screen3.png";
import incremento from "../../assets/incremento.png";
import foco from "../../assets/foco.png";
import internet from "../../assets/internet.png";
import logoGrande from "../../assets/logograndepng.png";
import Footer from "../Footer/footer.component";
import Box from "../seccionMovil/seccionMovil";
import { useInView, InView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import Form from "../Form/form.component";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logoragaland from '../../assets/logoragaland.png'
import logomicrosoft from '../../assets/logomicrosoft.png'
import logocaletaland from '../../assets/logocaletaland.png'
import logosuvidland from '../../assets/logosuvidland.png'
import logoutec from '../../assets/logoutec.png'
import logopuraterraland from '../../assets/logopuraterraland.png'
import ReactGA from 'react-ga4';
import "./body.styles.scss";

const Body = () => {
  const [windowSize, setWindowWidth] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const mobile = windowSize >= 650 ? false : true;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    ReactGA.event({action:'pageview',category:"Landing",label:"Visita"})

    window.addEventListener("resize", handleWindowResize);
    setSearch("");

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div style={{ marginBottom: "200px", color: "#000" }}>
      <section
        id="sec1"
        className="section1"
        style={{
          backgroundImage: `url(${
            mobile
              ? "https://www.cartaviva.pe/images/landing/banners/bannerunetemobil.jpg"
              : "https://www.cartaviva.pe/images/landing/banners/banneruneteweb.jpg"
          })`,
          backgroundSize: "cover",
        }}
      >
        <div className="anchori" id="Cvanchor"></div>
        <div className="CarouselContainer"></div>
        <div id="content" className="contentContainer visible">
          <div className="herocard2">
                    <div className="cartaviva">Vende sin comisiones.</div>
            <div className="slogan">
            Potencia tu negocio culinario con Carta Viva: la carta digital que impulsa tus ventas y te libera de comisiones. ¡Únete a nuestra comunidad de éxito hoy mismo!
            </div>
            <span
              className="calltoaction"
              onClick={() =>
                document
                  .getElementById("Anchorin")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Me interesaría conocer más
            </span>
          </div>
        </div>
      </section>
      <section id="sec2" className="section2">
        <div className="franjaMet">
          <span className="metricBox">
            <div className="metricTitle">Pedidos Exitosos</div>
            <div className="metricResult">12211</div>
          </span>
          <span className="metricBox">
            <div className="metricTitle">Ventas Realizadas</div>
            <div className="metricResult">S/ 380.431</div>
          </span>
          <span className="metricBox">
            <div className="metricTitle">Comisiones Ahorradas</div>
            <div className="metricResult">S/ 95,357</div>
          </span>
        </div>
       <div className="franjaMarcas">
       <div className="subtituloFranja">Marcas que confían en nosotros</div>
        <div className="logosCont">
            <span className="logoMarca"><img src={logocaletaland} alt="Logo Caleta" /></span>
            <span className="logoMarca"><img src={logoragaland} alt="Logo Ragazzi" /></span>
            <span className="logoMarca"><img src={logopuraterraland} alt="Logo Pura Terra" /></span>
            <span className="logoMarca"><img src={logosuvidland} alt="Logo Suvid" /></span>
          </div>
        </div>
        
        <Box title={"Ingresa con fuerza a un mundo digitalizado"}screen={join1} invertido>
        Es indudable que tus clientes se han digitalizado y la mayoría de sus pedidos se realizan a través de aplicaciones. Sin embargo, ¿a qué costo? Estos canales imponen comisiones exorbitantes que diluyen tus esfuerzos diarios.
        </Box>
        <Box title={"Aumenta la rentabilidad de tu negocio"} screen={join4}>
        ¿Sabías que entre comisiones e impuestos, tus ingresos pueden disminuir hasta un 50%? Deja de pagar más que tu competencia y olvídate de tener que rogar por un pequeño aumento en tu comisión. Tu negocio vale mucho más y lo sabes. Con Carta Viva, aprovecha al máximo tus ganancias y recupera el control total de tu rentabilidad.
        </Box>
        <Box title="Herramientas de inteligencia digital" screen={join3} invertido>
        Obtén información valiosa para gestionar de manera eficiente tu carta y aprovecha al máximo los medios digitales para expandir tu alcance y atraer a más clientes. Descubre cuáles son los platos estrella de tu negocio y vende exactamente lo que tus clientes prefieren. Además, con Carta Viva, disfruta de la libertad de vender sin comisiones, permitiendo que tu negocio trabaje para ti.
        </Box>
        <div>
          <div className="centerCallText">
            ¡Es momento de que empresarios como tú vuelvan a tomar el control!
          </div>
          <div className="centerCall">
            <img src={join2} alt="" />
          </div>
        </div>
        <div className="duenorest">
          <div className="beneficios">
            <div className="beneficio">
              <div className="icoCont">
                <img className="beneficoico" src={incremento} alt="" />
              </div>
              <div className="beneficiotit">Rentabilidad</div>
              <div className="beneficiotext">
              Con nuestra carta digital, podrás gestionar tus pedidos de forma directa, sin incurrir en altas comisiones a intermediarios. Digitaliza tu carta y garantiza que tu esfuerzo se traduzca en un crecimiento constante.
              </div>
            </div>
            <div className="beneficio">
              <div className="icoCont">
                <img className="beneficoico" src={foco} alt="" />
              </div>
              <div className="beneficiotit">Información</div>
              <div className="beneficiotext">
              Recibe reportes precisos que te permitirán conocer los antojos de tus clientes y vender exactamente lo que les gusta. Evalúa la efectividad de tus estrategias y toma decisiones importantes respaldadas por información relevante.{" "}
              </div>
            </div>
            <div className="beneficio">
              <div className="icoCont">
                <img className="beneficoico" src={internet} alt="" />
              </div>
              <div className="beneficiotit">Digitalización</div>
              <div className="beneficiotext">
              La presencia digital es crucial para tu negocio. En Carta Viva, nuestro diseño intuitivo y moderno atrae a tus clientes digitalizados, manteniendo una conexión constante. Genera movimiento con una experiencia de usuario fluida.
              </div>
            </div>
          </div>
        </div>
      </section>

      <Form />
    </div>
  );
};

export default Body;


//<div className="franjaMarcas">
{/* <div className="subtituloFranja">Participaciones en Concursos Start Up</div>
<div className="logosCont">
  <span className="logoMarca"><img src={logoutec} alt="Logo Utec" /></span>
  <span className="logoMarca"><img src={logomicrosoft} alt="Logo Microsoft" /></span>
</div>
</div>  */}